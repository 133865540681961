<template>
  <div class="ma-2">
    <v-row dense>
      <v-col cols="12">
        <v-btn color="primary" @click="$router.push('/hostel/list')" tile outlined>
          <v-icon> mdi-arrow-left </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="3" v-for="i in 4" :key="i">
        <v-card outlined min-height="100" class="d-flex align-center">
          <v-layout row wrap align-center class="ma-0">
            <v-flex align-self-center lg3 class="text-center">
              <v-icon> mdi-anchor </v-icon>
            </v-flex>
            <v-flex align-self-center lg9>
              <div>
                <span class="d-block font-weight-medium grey--text text--darken-3 body-2"> Visitors in Campus </span>
                <span class="d-block font-weight-black"> 25 </span>
              </div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="6">
        <v-card outlined>
          <v-card-title class="pt-2 pb-0 font-weight-bold grey--text darken-4">
            Blocks
          </v-card-title>
          <v-card-text>
            <data-iterator :headers="payloadHeaders" :payload="payload" :key="reInitHistels" class="elevation-0"></data-iterator>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card outlined>
          <v-card-title class="pt-2 pb-0 font-weight-bold grey--text darken-4">
            Floors
          </v-card-title>
          <v-card-text>
            <data-iterator :headers="payloadHeaders" :payload="payload" :key="reInitHistels" class="elevation-0"></data-iterator>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card outlined>
          <v-card-title class="pt-2 pb-0 font-weight-bold grey--text darken-4">
            Rooms
          </v-card-title>
          <v-card-text>
            <data-iterator :headers="payloadHeaders" :payload="payload" :key="reInitHistels" class="elevation-0"></data-iterator>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card outlined>
          <v-card-title class="pt-2 pb-0 font-weight-bold grey--text darken-4">
            Beds
          </v-card-title>
          <v-card-text>
            <data-iterator :headers="payloadHeaders" :payload="payload" :key="reInitHistels" class="elevation-0"></data-iterator>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      payload: {
        showBackBtn: false,
        create: () => this.drawer = true,
        items: [],
        showSelect: false,
        actionsList: [{
          click: (item) => this.editHandler(item._id),
          icon: 'mdi-pencil',
          color: 'primary'
        }, {
          click: (item) => this.deleteHandler(item._id),
          icon: 'mdi-delete',
          color: 'error'
        }]
      },
      tab: 0,
      reInitHistels: 0
    }
  },
  components: {
   'data-iterator': () => import('@/components/DataIterator.vue'),
  },
  computed: {
    payloadHeaders () {
      return [{
        text: 'Name',
        value: 'name'
      }, {
        text: 'Actions',
        value: 'actions'
      }]
    }
  }
}
</script>

<style>
.theme--light.v-tabs-items {
  background-color: rgba(255, 255, 255, 0) !important;
}
</style>